.logo {
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
  cursor: pointer;
}

.logo .title {
  color: #0d2a7d;
  font-family: "Inter", Helvetica;
  font-weight: 700;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.logo .logo-icon {
  height: 24px !important;
  min-width: 24px !important;
  position: relative !important;
}

.logo .mob {
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 26px;
}

.logo .web {
  font-size: 28px;
  letter-spacing: 0.56px;
  line-height: 36.4px;
}
