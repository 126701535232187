.reviews {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.reviews .title {
  color: var(--primary);
  font-family: var(--title-font-family);
  font-size: var(--title-font-size);
  font-style: var(--title-font-style);
  font-weight: var(--title-font-weight);
  letter-spacing: var(--title-letter-spacing);
  line-height: var(--title-line-height);
  margin-top: -0.88px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.reviews .div {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 0px 140px;
  position: relative;
}

.reviews .text-wrapper {
  align-self: stretch;
  color: var(--primary);
  font-family: var(--title-font-family);
  font-size: var(--title-font-size);
  font-style: var(--title-font-style);
  font-weight: var(--title-font-weight);
  letter-spacing: var(--title-letter-spacing);
  line-height: var(--title-line-height);
  margin-top: -0.88px;
  position: relative;
}

.reviews.is-mob-false {
  gap: 42px;
  padding: 48px 0px;
  margin-top: 50px;
}

.reviews.is-mob-true {
  gap: 32px;
  padding: 48px 24px;
  width: 390px;
}
