.menu {
  align-items: center;
  display: flex;
  position: relative;
  margin-left: 70px;
}

.menu .menu-icon {
  height: 32px !important;
  min-width: 32px !important;
  position: relative !important;
}

.menu.device-mob {
  gap: 10px;
  height: 32px;
  justify-content: space-around;
  padding: 0px 12px;
  width: 56px;
}

.menu.device-web {
  gap: 96px;
  width: fit-content;
}

.nav-bar {
  align-items: center;
  display: flex;
  gap: 48px;
  position: relative;
  width: fit-content;
}

.nav-bar .nav-item {
  min-width: 38px !important;
  width: unset !important;
}
