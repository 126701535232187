.home-page {
  align-items: center;
  border: 1px none;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-bottom: 1000px;
}
