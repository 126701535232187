.header {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  z-index: 10;
}

.header .container-web {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;
  margin: 0 140px;
  padding: 8px 0;
}

.header .container-mob {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;
  margin: 0 12px;
  padding: 12px 0;
}

.header .logo-wrapper {
  height: 32px;
  min-width: 157px;
  position: relative;
  margin: 0 12px;
}

.header .logo-instance {
  left: 12px !important;
  top: 4px !important;
}

.header .logoicon-1 {
  height: 24px !important;
  min-width: 24px !important;
  position: relative !important;
}

.header.device-0-mob {
  justify-content: space-between;
  padding: 12px;
  /* width: 390px; */
}

.header.device-0-web {
  justify-content: center;
  padding: 8px 140px;
  /* width: 1280px; */
}
