.button {
  border-radius: 4px;
  display: flex;
  padding: 8px 12px;
  position: relative;
  width: fit-content;
  cursor: pointer;
}

.button .label {
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button.style-default {
  align-items: flex-start;
  background-color: #0d2a7d;
}

.button.style-outline {
  align-items: center;
  border: 1.5px solid;
  border-color: #0d2a7d;
  justify-content: center;
}

.button.style-link {
  align-items: flex-start;
}

.button.style-default .label {
  color: #ffffff;
  margin-top: -0.88px;
}

.button.style-outline .label {
  color: #0d2a7d;
  margin-top: -1.5px;
}

.button.style-link .label {
  color: #000c25;
  margin-top: -0.88px;
}
