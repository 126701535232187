.service-section {
  background-color: #f2f3f5;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.service-section .services {
  color: #000c25;
  font-family: "Inter", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 44.8px;
}

.service-section .class-5 {
  margin-right: -24px !important;
}

.service-section .feature-card-instance {
  align-self: stretch !important;
  width: unset !important;
}

.service-section.device-mob {
  align-items: flex-start;
  gap: 36px;
  padding: 48px 24px;
}

.service-section.device-web {
  align-items: center;
  gap: 24px;
  padding: 48px 140px;
}

.tabs-list-mob {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.tabs-list-mob::-webkit-scrollbar {
  display: none;
}
