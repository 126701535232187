.hero-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
  flex: 1;
}

.hero-section .headline {
  display: flex;
  flex-direction: column;
}

.hero-section .normal {
  position: relative;
}

.hero-section .driving {
  color: #000c25;
  font-family: "Inter", Helvetica;
  font-weight: 300;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
}

.hero-section .growth-wrapper {
  align-items: flex-start;
  display: flex;
  padding: 0px 4px;
  width: fit-content;
}

.hero-section .growth {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-weight: 300;
  letter-spacing: 0;
  margin-top: -0.88px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.hero-section .with {
  color: #000c25;
  font-family: "Inter", Helvetica;
  font-weight: 300;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
}

.hero-section .strong {
  align-self: stretch;
  color: #000c25;
  font-family: "Inter", Helvetica;
  font-weight: 800;
  letter-spacing: 0;
  position: relative;
}

.hero-section .subtitle {
  color: #000c25;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.2px;
}

.hero-section .span {
  color: #000c25;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.2px;
}

.hero-section .text-wrapper-2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.hero-section .class {
  margin-top: -0.88px !important;
}

.hero-section.state-1-web {
  align-items: center;
  padding: 48px 140px;
  margin-top: 140px;
}

.hero-section.state-1-mob {
  align-items: flex-start;
  padding: 36px 24px;
  margin-top: 48px;
}

.hero-section.state-1-web .headline {
  align-items: center;
  justify-content: center;
}

.hero-section.state-1-mob .headline {
  align-items: flex-start;
}

.hero-section.state-1-web .normal {
  align-items: flex-start;
  display: flex;
  gap: 12.25px;
  width: fit-content;
}

.hero-section.state-1-mob .normal {
  align-self: stretch;
  height: 156px;
  min-width: 342px;
}

.hero-section.state-1-web .driving {
  font-size: 80px;
  line-height: 112px;
  margin-top: -0.88px;
  position: relative;
  width: fit-content;
}

.hero-section.state-1-mob .driving {
  font-size: 56px;
  height: 78px;
  left: 0;
  line-height: 78.4px;
  position: absolute;
  top: -1px;
}

.hero-section.state-1-web .growth-wrapper {
  position: relative;
}

.hero-section.state-1-mob .growth-wrapper {
  left: 0;
  position: absolute;
  top: 78px;
}

.hero-section.state-1-web .growth {
  font-size: 80px;
  line-height: 112px;
}

.hero-section.state-1-mob .growth {
  font-size: 56px;
  line-height: 78.4px;
}

.hero-section.state-1-web .with {
  font-size: 80px;
  line-height: 112px;
  margin-top: -0.88px;
  position: relative;
  width: fit-content;
}

.hero-section.state-1-mob .with {
  font-size: 56px;
  height: 78px;
  left: 192px;
  line-height: 78.4px;
  position: absolute;
  top: 77px;
}

.hero-section.state-1-web .strong {
  font-size: 80px;
  line-height: 112px;
  text-align: center;
}

.hero-section.state-1-mob .strong {
  font-size: 40px;
  line-height: 56px;
}

.hero-section.state-1-web .subtitle {
  text-align: center;
}
