body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

h1 {
  font-size: 2em;
  color: #4a4a4a;
}
h2 {
  font-size: 1.75em;
  color: #4a4a4a;
}
h3 {
  font-size: 1.5em;
  color: #4a4a4a;
}
h4 {
  font-size: 1.25em;
  color: #6a6a6a;
}
p {
  font-size: 1em;
  line-height: 1.6;
  color: #666;
  margin-top: 0;
  margin-bottom: 1em;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
