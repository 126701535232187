.footer {
  background-color: var(--gray-100);
  display: flex;
  justify-content: center;
  position: relative;
}

.footer .div {
  align-items: flex-start;
  background-color: var(--gray-100);
  border-color: var(--gray-300);
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  justify-content: space-between;
  margin-left: -140px;
  margin-right: -140px;
  padding: 32px 140px 64px;
  position: relative;
  width: 100%;
}

.footer .div-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: fit-content;
}

.footer .text-wrapper {
  color: var(--text-bg1000);
  font-family: var(--label-font-family);
  font-size: var(--label-font-size);
  font-style: var(--label-font-style);
  font-weight: var(--label-font-weight);
  letter-spacing: var(--label-letter-spacing);
  line-height: var(--label-line-height);
  margin-top: -0.88px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer .contact {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 205.62px;
}

.footer .item {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 8px;
  position: relative;
}

.footer .text-wrapper-2 {
  color: var(--text-bg600);
  flex: 1;
  font-family: var(--body-s-font-family);
  font-size: var(--body-s-font-size);
  font-style: var(--body-s-font-style);
  font-weight: var(--body-s-font-weight);
  letter-spacing: var(--body-s-letter-spacing);
  line-height: var(--body-s-line-height);
  margin-top: -0.88px;
  position: relative;
  white-space: nowrap;
}

.footer .instance-node {
  height: 20px !important;
  min-width: 20px !important;
  position: relative !important;
}

.footer .element-hampshire-st {
  color: var(--text-bg600);
  flex: 1;
  font-family: var(--body-s-font-family);
  font-size: var(--body-s-font-size);
  font-style: var(--body-s-font-style);
  font-weight: var(--body-s-font-weight);
  letter-spacing: var(--body-s-letter-spacing);
  line-height: var(--body-s-line-height);
  margin-top: -0.88px;
  position: relative;
}

.footer .services {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 162px;
}

.footer .UX-UI-design {
  align-self: stretch;
  color: var(--text-bg600);
  font-family: var(--body-s-font-family);
  font-size: var(--body-s-font-size);
  font-style: var(--body-s-font-style);
  font-weight: var(--body-s-font-weight);
  letter-spacing: var(--body-s-letter-spacing);
  line-height: var(--body-s-line-height);
  margin-top: -0.88px;
  position: relative;
  white-space: nowrap;
}

.footer .text-wrapper-3 {
  align-self: stretch;
  color: var(--text-bg600);
  font-family: var(--body-s-font-family);
  font-size: var(--body-s-font-size);
  font-style: var(--body-s-font-style);
  font-weight: var(--body-s-font-weight);
  letter-spacing: var(--body-s-letter-spacing);
  line-height: var(--body-s-line-height);
  position: relative;
  white-space: nowrap;
}

.footer .subscribe {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.footer .div-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: fit-content;
}

.footer .join-our-weekly-blog {
  align-self: stretch;
  color: var(--text-bg600);
  font-family: var(--body-s-font-family);
  font-size: var(--body-s-font-size);
  font-style: var(--body-s-font-style);
  font-weight: var(--body-s-font-weight);
  letter-spacing: var(--body-s-letter-spacing);
  line-height: var(--body-s-line-height);
  margin-top: -0.88px;
  position: relative;
}

.footer .input {
  height: 36px;
  min-width: 304px;
  position: relative;
}

.footer .overlap-group {
  height: 36px;
  position: relative;
  width: 304px;
}

.footer .your-email-wrapper {
  align-items: flex-start;
  background-color: var(--gray-100);
  border: 1px solid;
  border-color: var(--gray-300);
  border-radius: 3px;
  display: flex;
  left: 0;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  width: 260px;
}

.footer .your-email {
  color: var(--text-bg500);
  font-family: var(--body-s-font-family);
  font-size: var(--body-s-font-size);
  font-style: var(--body-s-font-style);
  font-weight: var(--body-s-font-weight);
  letter-spacing: var(--body-s-letter-spacing);
  line-height: var(--body-s-line-height);
  margin-top: -1px;
  opacity: 0.7;
  position: relative;
  white-space: nowrap;
  width: 200px;
}

.footer .action {
  align-items: center;
  background-color: var(--main);
  border-radius: 0px 4px 4px 0px;
  display: flex;
  justify-content: center;
  left: 256px;
  padding: 8px 14px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.footer .socials {
  align-items: flex-start;
  border-color: var(--gray-200);
  border-left-style: solid;
  border-left-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 0px 0px 40px;
  position: relative;
  width: fit-content;
}

.footer .stay-connected {
  color: var(--text-bg1000);
  font-family: var(--label-font-family);
  font-size: var(--label-font-size);
  font-style: var(--label-font-style);
  font-weight: var(--label-font-weight);
  letter-spacing: var(--label-letter-spacing);
  line-height: var(--label-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer .item-2 {
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 4px 0px;
  position: relative;
  width: fit-content;
}

.footer .text-wrapper-4 {
  color: var(--text-bg600);
  font-family: var(--label-font-family);
  font-size: var(--label-font-size);
  font-style: var(--label-font-style);
  font-weight: var(--label-font-weight);
  letter-spacing: var(--label-letter-spacing);
  line-height: var(--label-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer .twitter {
  color: var(--text-bg600);
  font-family: var(--label-font-family);
  font-size: var(--label-font-size);
  font-style: var(--label-font-style);
  font-weight: var(--label-font-weight);
  height: 17px;
  letter-spacing: var(--label-letter-spacing);
  line-height: var(--label-line-height);
  position: relative;
  white-space: nowrap;
  width: 47.42px;
}

.footer .copyrights {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 140px;
  position: absolute;
  top: 300px;
  width: fit-content;
}

.footer .etlyn-cookie {
  color: var(--gray-600);
  font-family: var(--label-font-family);
  font-size: var(--label-font-size);
  font-style: var(--label-font-style);
  font-weight: var(--label-font-weight);
  letter-spacing: var(--label-letter-spacing);
  line-height: var(--label-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer .div-4 {
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
}

.footer .text-wrapper-5 {
  color: var(--text-bg600);
  font-family: var(--body-s-font-family);
  font-size: var(--body-s-font-size);
  font-style: var(--body-s-font-style);
  font-weight: var(--body-s-font-weight);
  letter-spacing: var(--body-s-letter-spacing);
  line-height: var(--body-s-line-height);
  margin-top: -0.88px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer .p {
  color: var(--text-bg600);
  font-family: var(--body-s-font-family);
  font-size: var(--body-s-font-size);
  font-style: var(--body-s-font-style);
  font-weight: var(--body-s-font-weight);
  letter-spacing: var(--body-s-letter-spacing);
  line-height: var(--body-s-line-height);
  margin-top: -0.88px;
  position: relative;
  width: 177.62px;
}

.footer .frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.footer .text-wrapper-6 {
  color: var(--text-bg600);
  font-family: var(--body-s-font-family);
  font-size: var(--body-s-font-size);
  font-style: var(--body-s-font-style);
  font-weight: var(--body-s-font-weight);
  letter-spacing: var(--body-s-letter-spacing);
  line-height: var(--body-s-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer .socials-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: fit-content;
}

.footer .subscribe-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
}

.footer .frame-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.footer .overlap-group-wrapper {
  align-self: stretch;
  height: 36px;
  min-width: 342px;
  position: relative;
}

.footer .overlap-group-2 {
  border-radius: 3px;
  height: 36px;
  position: relative;
  width: 342px;
}

.footer .div-wrapper {
  align-items: flex-start;
  background-color: var(--gray-100);
  border: 1px solid;
  border-color: var(--gray-300);
  border-radius: 3px;
  display: flex;
  left: 0;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  width: 342px;
}

.footer .arrow-icon-wrapper {
  align-items: center;
  background-color: var(--main);
  border-radius: 0px 4px 4px 0px;
  display: flex;
  justify-content: center;
  left: 294px;
  padding: 8px 14px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.footer .logo-icon {
  height: 18px !important;
  min-width: 18px !important;
  position: relative !important;
}

.footer.is-mob-false {
  align-items: center;
  width: 100%;
}

.footer.is-mob-true {
  align-items: flex-start;
  flex-direction: column;
  gap: 48px;
  padding: 48px 24px;
  width: 390px;
}
